<template>
    <div>
        <div class="mx-2 section">
            <h2>Sales</h2>
            <div class="exclude-from-print">
                <br/>
            </div>
            <v-skeleton-loader class="mt-4" ref="skeleton" type="table" v-if="isLoadingSales">
            </v-skeleton-loader>
            <div v-if="this.totalSalesByEndDate">
                <div v-for="(item, index) in this.totalSalesByEndDate" :key="index">
                    <h3>Year {{ item.year }}</h3>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">New Sales in {{ item.year }}</p>
                        <p class="text-h7">{{ item.total_sales }}</p>
                    </div>
                    <PageTargetSales :label="'Target Sales in ' + item.year" :storageKey="'targetSales' + item.year"
                        :defaultTarget="0" :newSales="item.total_sales" />

                    <div v-if="filteredMonthSales(item.year).length">
                        <div v-for="(salesItem, salesIndex) in filteredMonthSales(item.year)" :key="salesIndex">
                            <div class="d-flex justify-space-between">
                                <p class="text-h7">{{ formatMonthDate(salesItem.month) }} {{ salesItem.year }} New Sales</p>
                                <p class="text-h7">{{ salesItem.total_sales }}</p>
                            </div>
                        </div>
                    </div><br>
                </div>
            </div>
            <div v-if="this.salesByWeek">
                <div v-for="(item, weekIndex) in this.salesByWeek" :key="weekIndex">
                    <h3>Week: {{ formatDate(item.start_date) }} – {{ formatDate(item.end_date) }}</h3>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">New Sales</p>
                        <p class="text-h7">{{ item.total_sales }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTargetSales from '../analysis/PageTargetSales.vue';

export default {
    components: {
        PageTargetSales,
    },
    props: {
        endDate: String,
        startDate: String,
    },
    computed: {
    },
    data() {
        return {
            api: {
                isSuccessful: false,
                isError: false,
                isLoading: false,
                error: null,
                url: null,
            },
            isLoadingSales: true,
            selectedEndDate: this.endDate,
            activeSubscribingCompanyByWeek: null,
            totalSalesByEndDate: null,
            monthSalesByEndDate: null,
            salesByWeek: null,
        };
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getSalesByWeek") {
                this.salesByWeek = resp.data;
                this.isLoadingSales = false;
            }
            if (resp.class === "getTotalSalesByEndDate") {
                this.totalSalesByEndDate = resp.data;
                this.isLoadingSales = false;
            }
            if (resp.class === "getMonthSalesByEndDate") {
                this.monthSalesByEndDate = resp.data;
                this.isLoadingSales = false;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },
    watch: {
        startDate: {
            handler(newStartDate) {
                this.selectedEndDate = newStartDate;
                this.isLoadingSales = true;
                this.fetch();
            },
            immediate: true,
        },
        endDate: {
            handler(newEndDate) {
                this.selectedEndDate = newEndDate;
                this.isLoadingSales = true;
                this.fetch();
            },
            immediate: true,
        },
    },
    methods: {
        filteredMonthSales(year) {
            return this.monthSalesByEndDate.filter(item => item.year === year);
        },
        fetch() {
            let fetchSalesByWeekApi = this.fetchSalesByWeek();
            let fetchActiveSubscribingCompanyByWeekApi = this.fetchActiveSubscribingCompanyByWeek();
            let fetchTotalSalesByEndDateApi = this.fetchTotalSalesByEndDate();
            let fetchMonthSalesByEndDateApi = this.fetchMonthSalesByEndDate();
            this.$axios.all([
                this.$api.fetch(fetchSalesByWeekApi),
                this.$api.fetch(fetchActiveSubscribingCompanyByWeekApi),
                this.$api.fetch(fetchTotalSalesByEndDateApi),
                this.$api.fetch(fetchMonthSalesByEndDateApi),
            ]);
        },
        fetchSalesByWeek() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = `${process.env.VUE_APP_SERVER_API}/analysis/sales/week/${this.startDate}/${this.endDate}`;
            return tempApi;
        },
        fetchActiveSubscribingCompanyByWeek() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = `${process.env.VUE_APP_SERVER_API}/analysis/company/active/week/${this.startDate}/${this.endDate}`;
            return tempApi;
        },
        fetchTotalSalesByEndDate() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = `${process.env.VUE_APP_SERVER_API}/analysis/sales/total/${this.startDate}/${this.endDate}`;
            return tempApi;
        },
        fetchMonthSalesByEndDate() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = `${process.env.VUE_APP_SERVER_API}/analysis/sales/month/${this.startDate}/${this.endDate}`;
            return tempApi;
        },
        formatDate(date) {

            if (!(date instanceof Date)) {
                date = new Date(date);
            }

            if (!(date instanceof Date && !isNaN(date))) {
                return 'Invalid Date';
            }

            const day = date.getDate().toString();
            const month = date.toLocaleDateString('en-US', { month: 'long' });
            const year = date.getFullYear();

            return `${day} ${month} ${year}`;
        },
        formatMonthDate(selectedMonth) {
            if (!(selectedMonth instanceof Date)) {
                selectedMonth = new Date(selectedMonth);
            }

            const month = selectedMonth.toLocaleDateString('en-US', { month: 'long' });
            return `${month}`;
        }
    },
}

</script>

<style scoped>
.section {
    padding: 15px;
    margin-bottom: 15px;
}

.section h3 {
    margin-bottom: 10px;
}
</style>