<template>
    <div>
        <v-skeleton-loader class="mt-4" ref="skeleton" type="table" v-if="isLoadingRenewalAllTime">
        </v-skeleton-loader>
        <div v-if="this.renewalAllTime">
            <div v-for="(item, key) in this.renewalAllTime" :key="key">
                <div v-if="item.subscription_year == subscriptionYear">
                    <h3>All-time Year {{ item.subscription_year }} Renewals to date - {{ formatLongDate(endDate) }} (Cut-off Date)</h3>
                    <div class="d-flex justify-space-between mt-3">
                        <p class="text-h7">Renewal</p>
                        <p class="text-h7">{{ item[`Renewed - Year ${subscriptionYear}`] }}</p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">Total</p>
                        <p class="text-h7">{{ item.total_count }}</p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">Percentage Achieved</p>
                        <p class="text-h7">{{ calculatePercentageAchieved(item[`Renewed - Year ${subscriptionYear}`], item.total_count) }}%</p>
                    </div>
                    <table class="styled-table">
                    <tr>
                        <td>Total Due</td>
                        <td>{{ item.total_count }}</td>
                    </tr>
                    <tr>
                        <td>Renewed</td>
                        <td>{{ item[`Renewed - Year ${subscriptionYear}`] }}</td>
                    </tr>
                    <tr>
                        <td>Confirmed but pending payment</td>
                        <td>{{ item[`Confirmed`] }}</td>
                    </tr>
                    <tr>
                        <td>Pending client decision</td>
                        <td>{{ Number(item[`Pending`]) + Number(item[`N/A`]) }}</td>
                    </tr>
                    <tr>
                        <td>Cancelled</td>
                        <td>{{ Number(item[`Cancelled`]) + Number(item[`Rejected`]) }}</td>
                    </tr>
                    </table><br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        endDate: String,
        subscriptionYear: Number,
    },
    computed: {
        ...mapState({}),
    },
    data: () => ({
        api: {
            isSuccesful: false,
            isError: false,
            isLoading: false,
            error: null,
            url: null,
        },
        renewalAllTime: null,
        isLoadingRenewalAllTime:true,
    }),
    watch: {
        endDate: {
            handler(newEndDate) {
                this.selectedEndDate = newEndDate;
                this.isLoadingRenewalAllTime = true;
                this.fetch();
            },
            immediate: true,
        },
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getRenewalAllTime") {
                this.renewalAllTime = resp.data;
                this.isLoadingRenewalAllTime = false;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },

    methods: {
        calculatePercentageAchieved(renewal, total) {
            if (renewal === 0) {
                return 0;
            }
            return ((renewal / total) * 100).toFixed(2);
        },
        formatLongDate(selectedDate) {
            const date = new Date(selectedDate);
            const day = date.toLocaleDateString('en-US', { day: 'numeric' });
            const month = date.toLocaleDateString('en-US', { month: 'long' });
            const year = date.toLocaleDateString('en-US', { year: 'numeric' });
            return `${day} ${month} ${year}`;
        },
        fetch() {
            let fetchRenewalAllTimeApi = this.fetchRenewalAllTime();
            this.$axios.all([
                this.$api.fetch(fetchRenewalAllTimeApi),
            ]);
        },
        fetchRenewalAllTime() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = `${process.env.VUE_APP_SERVER_API}/analysis/renewal/alltime/${this.endDate}`;
            return tempApi;
        },
    },
}
</script>

<style scoped>
hr {
    border-color: hsl(0, 1%, 55%);
    opacity: 20%;
}

.styled-table {
    border-collapse: collapse;
    width: 100%;
}

.styled-table th,
.styled-table td {
    border: 1px solid #ddd;
    padding: 8px;
}
</style>