<template>
    <v-container ref="pdfContent">
        <div class="printable-container">
            <div class="exclude-from-print">
                <v-toolbar color="secondary">
                    <h2 style="color:white">7 Days Report</h2>
                </v-toolbar>
            </div>
            <v-card class="pa-5">
                <div class="exclude-from-print">
                    <br/>
                </div>
                <v-row>
                    <v-col cols="6">
                        <div v-if="startDate && endDate">
                            <div class="mx-3 section custom-box">
                                <div class="d-flex justify-space-between" v-if="!isLoadingUsers">
                                    <h3>Total active subscribers</h3>
                                    <div v-if="this.activeSubscribingCompanyByWeek">
                                        <div v-for="(item, index) in this.activeSubscribingCompanyByWeek" :key="index">
                                            <h3>{{ item.subscription_count }}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="d-flex flex-row">
                            <div class="exclude-from-print">
                                <v-text-field style="border-radius:4px 0px 0px 4px" dense outlined
                                    prepend-inner-icon="mdi-calendar"
                                    @click:prepend-inner="() => { isStartDate = true; }"
                                    @click="() => { isStartDate = true; }" label="Start Date..."
                                    v-model="filter.selectedStartDate">
                                </v-text-field>
                                <v-dialog v-model="isStartDate" height="auto" width="auto">
                                    <v-card class="pa-3">
                                        <div class="d-flex flex-column">
                                            <p>
                                                Start Date
                                            </p>
                                            <div>
                                                <v-date-picker flat v-model="filter.selectedStartDate" :landscape="true"
                                                    :reactive="true">
                                                </v-date-picker>
                                                <slot name="footer">
                                                    <div class="d-flex justify-end white">
                                                        <v-btn color="primary" plain
                                                            @click="() => { isStartDate = false; filter.selectedStartDate = null; }">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn color="primary" @click="() => { isStartDate = false; }">
                                                            Confirm
                                                        </v-btn>
                                                    </div>
                                                </slot>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-dialog>
                            </div>
                            <div class="exclude-from-print">
                                <v-text-field dense outlined style="border-radius:0px" prepend-inner-icon="mdi-calendar"
                                    @click:prepend-inner="() => { isEndDate = true; }"
                                    @click="() => { isEndDate = true; }" label="End Date..."
                                    v-model="filter.selectedEndDate">
                                </v-text-field>
                                <v-dialog v-model="isEndDate" height="auto" width="auto">
                                    <v-card class="pa-3">
                                        <div class="d-flex flex-column">
                                            <p>
                                                End Date
                                            </p>
                                            <div>
                                                <v-date-picker flat v-model="filter.selectedEndDate" :landscape="true"
                                                    :reactive="true">
                                                </v-date-picker>
                                            </div>
                                        </div>
                                        <div name="footer">
                                            <div class="d-flex justify-end my-3">
                                                <v-btn color="primary" plain
                                                    @click="() => { isEndDate = false; filter.selectedEndDate = null; }">
                                                    Cancel
                                                </v-btn>
                                                <v-btn color="primary" @click="() => { isEndDate = false; }">
                                                    Confirm
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-dialog>
                            </div>
                            <div class="exclude-from-print">
                                <v-row>
                                    <v-col>
                                        <v-btn color="primary" :loading="isLoadingFilter" @click="filterDate"
                                            style="border-radius:0px 4px 4px 0px;height:40px">
                                            Filter
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn color="primary" style="border-radius: 4px 4px 4px 4px; height: 40px"
                                            @click="generateReport" outlined>
                                            Download
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <div v-if="startDate && endDate">
                    <v-row>
                        <v-col class="pr-5" cols="12" sm="6">
                            <PageNewSales :startDate="startDate" :endDate="endDate" />
                            <hr class="my-4" />
                            <PageRenewal :renewalYear="2" :startDate="startDate" :endDate="endDate" />
                        </v-col>
                        <v-col class="pr-5" cols="12" sm="6">
                            <PageRenewal :renewalYear="3" :startDate="startDate" :endDate="endDate" />
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-card class="no-data-card">
                        <v-card-text>
                            <h3 class="no-data-message">
                                Select start and end dates to view the report.
                            </h3>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import PageRenewal from "../analysis/PageRenewal.vue";
import PageNewSales from "../analysis/PageNewSales.vue";

export default {
    components: {
        PageRenewal,
        PageNewSales,
    },
    data() {
        const currentDate = new Date();

        // Set start date (Friday from two weeks ago)
        const fixedStartDay = 5;
        const lastFriday = new Date(currentDate);
        lastFriday.setHours(12, 0, 0, 0);
        lastFriday.setDate(
            currentDate.getDate() -
            ((currentDate.getDay() + 4 + fixedStartDay + 14) % 14)
        );

        // Set end date (last Thursday)
        const fixedEndDay = 4;
        const lastThursday = new Date(currentDate);
        lastThursday.setHours(12, 0, 0, 0);
        lastThursday.setDate(
            currentDate.getDate() - ((currentDate.getDay() + 6 + fixedEndDay) % 7)
        );

        return {
            activeUsersByYear: null,
            activeSubscribingCompanyByWeek: null,
            isLoadingUsers: true,
            api: {
                isSuccesful: false,
                isError: false,
                isLoading: false,
                error: null,
                url: null,
            },
            filter: {
                selectedStartDate: lastFriday.toISOString().split("T")[0],
                selectedEndDate: lastThursday.toISOString().split("T")[0],
            },
            isStartDate: false,
            isEndDate: false,
            isLoadingFilter: false,
            startDate: lastFriday.toISOString().split("T")[0],
            endDate: lastThursday.toISOString().split("T")[0],
        };
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getActiveSubscribingUserByYear") {
                this.activeUsersByYear = resp.data;
                this.isLoadingUsers = false;
            }
            if (resp.class === "getActiveSubscribingCompanyByWeek") {
                this.activeSubscribingCompanyByWeek = resp.data;
                this.isLoadingUsers = false;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchActiveSubscribingCompanyByWeekApi =
                this.fetchActiveSubscribingCompanyByWeek();
            this.$axios.all([
                this.$api.fetch(fetchActiveSubscribingCompanyByWeekApi),
            ]);
        },
        fetchActiveSubscribingCompanyByWeek() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url =
                process.env.VUE_APP_SERVER_API + "/analysis/company/active/week/" + this.filter.selectedStartDate + "/" + this.filter.selectedEndDate;
            return tempApi;
        },
        filterDate() {
            if (this.filter.selectedStartDate && this.filter.selectedEndDate) {
                this.isLoadingFilter = true;
                setTimeout(() => {
                    this.startDate = this.filter.selectedStartDate;
                    this.endDate = this.filter.selectedEndDate;
                    this.isLoadingFilter = false;
                }, 3000);
            } else {
                console.warn('Please select both start and end dates.');
                this.isLoadingFilter = false;
            }
        },
        async generateReport() {
            const containerClone = this.$refs.pdfContent.cloneNode(true);

            const elementsToRemove = containerClone.querySelectorAll('.exclude-from-print');
            elementsToRemove.forEach(element => {
                element.parentNode.removeChild(element);
            });

            const content = containerClone.innerHTML;

            if (content) {
                const newWindow = window.open('', '_blank');
                newWindow.document.write('<html><head><title>7 Days Report</title>');
                newWindow.document.write('<style>');
                newWindow.document.write(`
                    body {
                        font-family: 'Arial, sans-serif';
                        width: 80%;
                        margin: 0 auto; /* Center the body content */
                    }

                    .styled-table {
                        border-collapse: collapse;
                        width: 50%;
                    }

                    .styled-table th,
                    .styled-table td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }

                    .d-flex {
                        display: flex;
                    }

                    .justify-space-between {
                        justify-content: space-between;
                    }

                    @media print {
                        .exclude-from-print {
                            display: none !important;
                        }

                        input[type="text"] {
                            content: attr(value);
                            font-size: inherit;
                            border: none;
                            padding: 0;
                            margin: 0;
                            background: none;
                            box-shadow: none;
                            outline: none;
                        }
                    }
                `);
                newWindow.document.write('</style>');

                newWindow.document.write('</head><body>');
                newWindow.document.write('<br><h1>7 Days Report</h1>');
                newWindow.document.write(content);
                newWindow.document.write('</body></html>');
                newWindow.document.close();

                newWindow.print();
            } else {
                console.error('PDF content not found.');
            }
        }


    },
};
</script>

<style scoped>
.section {
    padding: 15px;
    margin-bottom: 15px;
}

.section h3 {
    margin-bottom: 10px;
}

hr {
    border-color: hsl(0, 1%, 55%);
    opacity: 20%;
}

.custom-box {
    border: 1px solid #bebdbd;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 15px;
    height: 43px;
    line-height: 22px;
}

.no-data-card {
    text-align: center;
    margin-top: 20px;
    padding: 20px;
}

.no-data-message {
    color: rgb(34, 69, 115);
    font-size: 18px;
    margin: 0;
}
</style>