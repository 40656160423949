<template>
    <div>
        <div class="d-flex justify-space-between align-items-center">
            <p class="text-h7">{{ label }}</p>
            <input v-if="!isEditing" type="text" :value="editedTargetSales !== null ? editedTargetSales : targetSales"
                readonly @click="startEditing" class="text-h7 text-right custom-input" />
            <input v-else type="text" v-model="editedTargetSales" class="text-h7 text-right custom-input" />
        </div>
        <div v-if="isEditing" class="d-flex justify-end mb-2" style="margin-top: -10px;">
            <v-btn small color="primary" class="mr-2" @click="saveChanges">Save</v-btn>
            <v-btn small color="default" @click="cancelEditing">Cancel</v-btn>
        </div>
        <div class="d-flex justify-space-between">
            <p class="text-h7">Percentage Achieved</p>
            <p class="text-h7">{{ calculatePercentageAchieved(newSales, targetSales) }}%</p>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        label: String,
        storageKey: String,
        defaultTarget: Number,
        newSales: Number,
    },
    data() {
        return {
            editedTargetSales: null,
            isEditing: false,
            percentageAchieved: 0,
        };
    },
    computed: {
        targetSales: {
            get() {
                return localStorage.getItem(this.storageKey) || this.defaultTarget;
            },
            set(value) {
                localStorage.setItem(this.storageKey, value);
            },
        },
    },
    watch: {
        targetSales: 'recalculatePercentage',
        storageKey: 'fetchTargetSales',
    },
    methods: {
        startEditing() {
            if (this.editedTargetSales == null) {
                this.editedTargetSales = this.targetSales;
            }
            this.isEditing = true;
        },
        saveChanges() {
            localStorage.setItem(this.storageKey, JSON.stringify(this.editedTargetSales));
            this.targetSales = this.editedTargetSales;
            this.isEditing = false;
        },
        cancelEditing() {
            this.isEditing = false;
        },
        fetchTargetSales() {
            const storedValue = localStorage.getItem(this.storageKey);
            if (storedValue) {
                this.targetSales = JSON.parse(storedValue);
                this.editedTargetSales = this.targetSales;
            }
        },
        calculatePercentageAchieved(newSales, targetSales) {
            if (targetSales === 0) {
                return 0;
            }
            return ((newSales / targetSales) * 100).toFixed(2);
        },
        recalculatePercentage() {
            this.percentageAchieved = this.calculatePercentageAchieved(this.newSales, this.targetSales);
        },
    },
    mounted() {
        this.fetchTargetSales();
        this.recalculatePercentage();
    },
};
</script>
  
<style scoped>
.custom-input {
    height: 100%;
    padding-top: 0.2em;
    box-sizing: border-box;
}
</style>
  