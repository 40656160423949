<template>
    <div>
        <div v-if="renewalYear == '2'">
            <div class="mx-2 section">
                <h2>Year 2 Renewals</h2>
                <div class="exclude-from-print">
                    <br/>
                </div>
                <PageRenewalByYear :subscriptionYear="2" :startDate="startDate" :endDate="endDate" /><br>
                <PageRenewalByWeek :subscriptionYear="2" :startDate="startDate" :endDate="endDate" /><br>
                <PageRenewalAllTime :subscriptionYear="2" :endDate="endDate" />
            </div>
        </div>
        <div v-if="renewalYear > '2'">
            <div class="mx-2 section">
                <h2>Year {{ renewalYear }} Renewals</h2>
                <div class="exclude-from-print">
                    <br/>
                </div>
                <PageRenewalByWeek :subscriptionYear="renewalYear" :startDate="startDate" :endDate="endDate" /><br>
                <PageRenewalAllTime :subscriptionYear="renewalYear" :endDate="endDate" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import PageRenewalAllTime from '../analysis/PageRenewalAllTime.vue';
import PageRenewalByWeek from '../analysis/PageRenewalByWeek.vue';
import PageRenewalByYear from './PageRenewalByYear.vue';

export default {
    components: {
    PageRenewalAllTime,
    PageRenewalByWeek,
    PageRenewalByYear
},
    props: {
        endDate: String,
        startDate: String,
        renewalYear: Number,
    },
    computed: {
        ...mapState({}),
    },
    data() {
        return {
            api: {
                isSuccessful: false,
                isError: false,
                isLoading: false,
                error: null,
                url: null,
            },
            renewalAllTime: null,
            renewalByWeek: null,
        };
    },
    watch: {
        startDate: {
            handler(newStartDate) {
                this.selectedEndDate = newStartDate;
                this.fetch();
            },
            immediate: true,
        },
        endDate: {
            handler(newEndDate) {
                this.selectedEndDate = newEndDate;
                this.fetch();
            },
            immediate: true,
        },
    },
    created() {
    },
    mounted() {
    },

    methods: {
    },
}
</script>

<style scoped>
.section {
    padding: 15px;
    margin-bottom: 15px;
}

.section h3 {
    margin-bottom: 10px;
}
</style>