<template>
    <div>
        <v-skeleton-loader class="mt-4" ref="skeleton" type="table" v-if="isLoadingRenewalByWeek">
        </v-skeleton-loader>
        <div v-if="this.renewalByWeek">
            <div v-for="(item, key) in this.renewalByWeek" :key="key">
                <div v-if="item.end_date == formatDate(endDate) && item.subscription_year == subscriptionYear">
                    <h3>Week: {{ formatLongDate(startDate) }} – {{ formatLongDate(endDate) }}</h3>
                    <div class="d-flex justify-space-between mt-3">
                        <p class="text-h7">Renewal</p>
                        <p class="text-h7">{{ item[`Renewed - Year ${subscriptionYear}`] }}</p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">Total</p>
                        <p class="text-h7">{{ item.total_count }}</p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="text-h7">Percentage Achieved</p>
                        <p class="text-h7">{{ calculatePercentageAchieved(item[`Renewed - Year
                            ${subscriptionYear}`], item.total_count) }}%</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        endDate: String,
        startDate: String,
        subscriptionYear: Number,
    },
    computed: {
        ...mapState({}),
    },
    data: () => ({
        api: {
            isSuccesful: false,
            isError: false,
            isLoading: false,
            error: null,
            url: null,
        },
        renewalByWeek: null,
        isLoadingRenewalByWeek: true,
    }),
    watch: {
        startDate: {
            handler(newStartDate) {
                this.selectedEndDate = newStartDate;
                this.isLoadingRenewalByWeek = true;
                this.fetch();
            },
            immediate: true,
        },
        endDate: {
            handler(newEndDate) {
                this.selectedEndDate = newEndDate;
                this.isLoadingRenewalByWeek = true;
                this.fetch();
            },
            immediate: true,
        },
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getRenewalByWeek") {
                this.renewalByWeek = resp.data;
                this.isLoadingRenewalByWeek = false;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        calculatePercentageAchieved(renewal, total) {
            if (renewal === 0) {
                return 0;
            }
            return ((renewal / total) * 100).toFixed(2);
        },
        formatDate(selectedDate) {
            const date = new Date(selectedDate);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formatLongDate(selectedDate) {
            const date = new Date(selectedDate);
            const day = date.toLocaleDateString('en-US', { day: 'numeric' });
            const month = date.toLocaleDateString('en-US', { month: 'long' });
            const year = date.toLocaleDateString('en-US', { year: 'numeric' });
            return `${day} ${month} ${year}`;
        },
        fetch() {
            let fetchRenewalByWeekApi = this.fetchRenewalByWeek();
            this.$axios.all([
                this.$api.fetch(fetchRenewalByWeekApi),
            ]);
        },
        fetchRenewalByWeek() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = `${process.env.VUE_APP_SERVER_API}/analysis/renewal/week/${this.startDate}/${this.endDate}`;
            return tempApi;
        },
    },
}
</script>

<style scoped>
hr {
    border-color: hsl(0, 1%, 55%);
    opacity: 20%;
}

.styled-table {
    border-collapse: collapse;
    width: 100%;
}

.styled-table th,
.styled-table td {
    border: 1px solid #ddd;
    padding: 8px;
}
</style>